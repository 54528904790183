export const UpdateOrigin = {
  USER: "user",
  BACK_OFFICE: "back-office",
};

export const UpdateEnum = [
  { name: "Profil", code: "profile" },
  { name: "Bio", code: "bio" },
  { name: "Bio tuteur", code: "tutorBio" },
  { name: "Avatar", code: "avatar" },
  { name: "Avatar tuteur", code: "tutorAvatar" },
  { name: "Diplôme", code: "diploma" },
  { name: "Carte d'identité", code: "identityCard" },
  { name: "Carte d'identité (recto)", code: "frontId" },
  { name: "Carte d'identité (verso)", code: "backId" },
  { name: "Casier judiciaire", code: "criminalRecord" },
  { name: "Curriculum Vitae", code: "curriculumVitae" },
  { name: "Kbis", code: "kbis" },
];

export const PremiumEnum = [{ name: "Premium", code: "PREMIUM_MONTHLY" }];

export const ProfileStatusEnum = [
  { name: "INCOMPLET", code: "INCOMPLETE", color: "orange" },
  { name: "BLOQUÉ", code: "BLOCKED", color: "red" },
  { name: "LIVE", code: "LIVE", color: "green" },
  { name: "DÉSINSCRIT", code: "UNSUBSCRIBED", color: "grey" },
  { name: "SUPPRIMÉ", code: "DELETED", color: "grey" },
  { name: "INACTIF_1", code: "INACTIF_1", color: "orange" },
  { name: "INACTIF_6", code: "INACTIF_6", color: "lightred" },
  { name: "INACTIF_12", code: "INACTIF_12", color: "red" },
];

export const userOrigin = [
  { name: "Annonce Gens de confiance", code: "GENS_DE_CONFIANCE" },
  { name: "Annonce Le bon Coin", code: "LE_BON_COIN" },
  { name: "Groupe Facebook", code: "FACEBOOK_GROUP" },
  { name: "Pub Facebook / Insta", code: "FACEBOOK_AD" },
  { name: "Recherche Store", code: "STORE_SEARCH" },
  { name: "Recherche Google", code: "GOOGLE_SEARCH" },
  { name: "Institution / Asso", code: "INSTITUTION" },
  { name: "Entreprise /licence Premium", code: "COMPANY" },
  { name: "Ne sait pas", code: "DONT_KNOW" },
  { name: "Other", code: "OTHER" },
  { name: "Non renseigné", code: "NC" },
];

export const diplomaType = [
  { name: "Dip. D'ET Aux. de Vie Sociale (DEAVS)", code: "DEAVS" },
  { name: "Dip. D'ET Acc. Educatif et Social (DEAES)", code: "DEAES" },
  {
    name: "Dip. D'ET Aide médico-psychologique (DEAMP)",
    code: "DEAMP",
  },
  { name: "Dip. D'ET Aide soignante (DEAS)", code: "DEAS" },
  {
    name: "Dip. D'ET Assistant de soin en gérontologie",
    code: "ASG",
  },
  {
    name: "Titre pro Assistant de vie aux familles (ADVF)",
    code: "ADVF",
  },
  { name: "BEPA service aux personnes", code: "BEPA" },
  {
    name: "BEP Carrières sanitaire et social (CSS)",
    code: "BEP_CSS",
  },
  {
    name: "BAC PRO Acc. Soins et Serv. à la pers ( ASSP)",
    code: "BAC_PRO_ASSP",
  },
];

export const HelperSoftSkillsData = {
  ORGANISED: "organised",
  RELIABLE: "reliable",
  NICE: "nice",
  FUNNY: "funny",
  ATTENTIONATE: "attentionate",
  VERSATILE: "versatile",
  DISCREET: "discreet",
  PLAYFUL: "playful",
  ONTIME: "onTime",
};

export const HelperSoftSkillsList = [
  HelperSoftSkillsData.ORGANISED,
  HelperSoftSkillsData.RELIABLE,
  HelperSoftSkillsData.NICE,
  HelperSoftSkillsData.FUNNY,
  HelperSoftSkillsData.ATTENTIONATE,
  HelperSoftSkillsData.VERSATILE,
  HelperSoftSkillsData.DISCREET,
  HelperSoftSkillsData.PLAYFUL,
  HelperSoftSkillsData.ONTIME,
];

export const HelperSoftSkills = {
  [HelperSoftSkillsData.ORGANISED]: { text: "Organisé(e)" },
  [HelperSoftSkillsData.RELIABLE]: { text: "Fiable" },
  [HelperSoftSkillsData.NICE]: { text: "Gentil(le)" },
  [HelperSoftSkillsData.FUNNY]: { text: "Drôle" },
  [HelperSoftSkillsData.ATTENTIONATE]: { text: "Attentif(ve)" },
  [HelperSoftSkillsData.VERSATILE]: { text: "Polyvalent(e)" },
  [HelperSoftSkillsData.DISCREET]: { text: "Discrèt(e)" },
  [HelperSoftSkillsData.PLAYFUL]: { text: "Enjoué(e)" },
  [HelperSoftSkillsData.ONTIME]: { text: "Ponctuel(le)" },
};

export const HelperExperienceData = {
  ALZHEIMER: "Alzheimer",
  COGNITIVE_IMPAIREMENT: "CognitiveImpairement",
  HANDICAP: "Handicap",
  DEGENERATIVE_DISEASE: "DegenerativeDisease",
  ATYPICAL_CHILD: "AtypicalChild",
};

export const HelperExperienceList = [
  HelperExperienceData.ALZHEIMER,
  HelperExperienceData.COGNITIVE_IMPAIREMENT,
  HelperExperienceData.HANDICAP,
  HelperExperienceData.DEGENERATIVE_DISEASE,
  HelperExperienceData.ATYPICAL_CHILD,
];

export const HelperExperience = {
  [HelperExperienceData.ALZHEIMER]: { text: "Alzheimer" },
  [HelperExperienceData.COGNITIVE_IMPAIREMENT]: {
    text: "Déficience cognitive",
  },
  [HelperExperienceData.HANDICAP]: { text: "Handicap" },
  [HelperExperienceData.DEGENERATIVE_DISEASE]: {
    text: "Maladie dégénérative",
  },
  [HelperExperienceData.ATYPICAL_CHILD]: { text: "Enfant atypique" },
};

export const JobHelperFormat = [
  { code: "Aide ménagère", name: "Aide ménagère" },
  { code: "Aide soignant.e diplômé.e", name: "Aide soignant.e diplômé.e" },
  { code: "Auxiliaire de vie diplômé.e", name: "Auxiliaire de vie diplômé.e" },
  {
    code: "Auxiliaire/Assistant.e de vie non diplômé.e",
    name: "Auxiliaire/Assistant.e de vie non diplômé.e",
  },
  {
    code: "Assistant.e de vie diplômé.e",
    name: "Assistant.e de vie diplômé.e",
  },
  { code: "Dame de compagnie", name: "Dame de compagnie" },
  { code: "Étudiant.e", name: "Étudiant.e" },
  { code: "Autre", name: "Autre" },
];

export const JobHelper = Object.freeze({
  CLEANING: "Aide ménagère",
  CAREGIVER: "Aide soignant.e diplômé.e",
  AUXILIAIRE: "Auxiliaire de vie diplômé.e",
  AUXILIAIRE_NO_DIPLOMA: "Auxiliaire/Assistant.e de vie non diplômé.e",
  ASSISTANT: "Assistant.e de vie diplômé.e",
  PERSONAL_CARER: "Dame de compagnie",
  STUDENT: "Étudiant.e",
  OTHER: "Autre",
});

export const JobHelproFormat = [
  { code: "Art-thérapeute", name: "Art-thérapeute" },
  { code: "Aide soignant.e diplômé.e", name: "Aide soignant.e diplômé.e" },
  { code: "Auxiliaire de vie diplômé.e", name: "Auxiliaire de vie diplômé.e" },
  {
    code: "Auxiliaire/Assistant.e de vie non diplômé.e",
    name: "Auxiliaire/Assistant.e de vie non diplômé.e",
  },
  {
    code: "Assistant.e de vie diplômé.e",
    name: "Assistant.e de vie diplômé.e",
  },
  {
    code: "Animateur.trice Musique et Chant",
    name: "Animateur.trice Musique et Chant",
  },
  { code: "Aide ménagère", name: "Aide ménagère" },
  {
    code: "Bricoleur et petits services",
    name: "Bricoleur et petits services",
  },
  { code: "Coach sportif", name: "Coach sportif" },
  { code: "Coach sportif Adapté", name: "Coach sportif Adapté" },
  { code: "Coiffeur.se à domicile", name: "Coiffeur.se à domicile" },
  { code: "Créateur.trice artistiques", name: "Créateur.trice artistiques" },
  { code: "Danse-thérapeute", name: "Danse-thérapeute" },
  { code: "Diététicien.ne", name: "Diététicien.ne" },
  { code: "Esthéticien.ne", name: "Esthéticien.ne" },
  { code: "Professeur.re de Yoga", name: "Professeur.re de Yoga" },
  { code: "Sophrologue", name: "Sophrologue" },
  { code: "Autre", name: "Autre" },
];

export const JobHelpro = {
  ART_THERAPIST: "Art-thérapeute",
  CAREGIVER: "Aide soignant.e diplômé.e",
  AUXILIAIRE: "Auxiliaire de vie diplômé.e",
  AUXILIAIRE_NO_DIPLOMA: "Auxiliaire/Assistant.e de vie non diplômé.e",
  ASSISTANT: "Assistant.e de vie diplômé.e",
  MUSIC_ANIMATOR: "Animateur.trice Musique et Chant",
  CLEANING: "Aide ménagère",
  HANDYMAN: "Bricoleur et petits services",
  SPORT_COACH: "Coach sportif",
  ADAPTED_SPORT_COACH: "Coach sportif Adapté",
  HAIRDRESSER: "Coiffeur.se à domicile",
  ART_CREATOR: "Créateur.trice artistiques",
  DANCE_THERAPIST: "Danse-thérapeute",
  DIETICIAN: "Diététicien.ne",
  BEAUTICIAN: "Esthéticien.ne",
  YOGA_TEACHER: "Professeur.re de Yoga",
  SOPHROLOGIST: "Sophrologue",
  OTHER: "Autre",
};

export function getJobValue(jobList, job) {
  var result = jobList.find((j) => j.name === job);
  if (result) {
    return result;
  }
  return jobList.find((j) => j.name === "Autre");
}

export const savUpdateType = [
  { name: "Nouveaux inscrits bloqués", code: "new_blocked" },
  { name: "Nouvelles modifications", code: "new_updates" },
  { name: "Permis de séjour", code: "work_permit_validity" },
  { name: "Bienvenue", code: "welcome_profiles" },
  { name: "Incomplets", code: "incomplete_profiles" },
  { name: "Bloqués mais qualité OK", code: "blocked_quality_ok" },
  {
    name: "Helpee avec nouveaux résultat de recherche",
    code: "can_like_again",
  },
  { name: "Helpee ans résulats de recherche", code: "cannot_like" },
  { name: "Helpee qui n'a pas eu de réponse", code: "helpee_no_answer" },
  {
    name: "Helpee qui n'a pas donné de réponse",
    code: "helpee_no_reply",
  },
  { name: "KO - Inscription/dossier trop anciens", code: "ancient_signup" },
  {
    name: "KO - Pas de réponse malgré actions réalisées",
    code: "profile_actions_done",
  },
];

export const missionApplicationStatus = {
  PENDING: "En attente",
  MESSAGE_SENT: "Mise en relation manuelle",
  AUTO_MESSAGE_SENT: "Mise en relation hors app",
  DECLINED: "Refusée",
};

export const missionApplicationStatusData = [
  { name: "En attente", code: "En attente" },
  { name: "Mise en relation manuelle", code: "Mise en relation manuelle" },
  { name: "Mise en relation hors app", code: "Mise en relation hors app" },
  { name: "Refusée", code: "Refusée" },
];

export const durationValues = [
  //{label: '30 minutes', value: '30min', id: 'duration_0.5'},
  { name: "1h", code: "1h" },
  { name: "1h30", code: "1h30" },
  { name: "2h", code: "2h" },
  { name: "3h", code: "3h" },
  { name: "4h", code: "4h" },
  { name: "5h", code: "5h" },
  { name: "journée (6-8 heures)", code: "day" },
  { name: "nuit (6-8 heures)", code: "night" },
];

export const UserRelationship = {
  FATHER: "Father",
  MOTHER: "Mother",
  CHILD: "Child",
  GRAND_PARENT: "Grandparents",
  SPOUSE: "Partner",
  SIBLING: "Sibling",
  FRIEND: "Friend",
  SELF: "Me",
  OTHER: "Other",
};

export const RelationShipData = {
  [UserRelationship.FATHER]: "Mon père",
  [UserRelationship.MOTHER]: "Ma mère",
  [UserRelationship.CHILD]: "Mon enfant",
  [UserRelationship.GRAND_PARENT]: "Mes grands-parents",
  [UserRelationship.SPOUSE]: "Mon conjoint",
  [UserRelationship.SIBLING]: "Mon frère, ma soeur",
  [UserRelationship.FRIEND]: "Mon ami, mon voisin",
  [UserRelationship.SELF]: "C’est moi !",
  [UserRelationship.OTHER]: "Autre",
};

export const relationOptions = Object.values(UserRelationship).map(
  (relation) => {
    return {
      name: RelationShipData[relation],
      code: relation,
    };
  }
);

export const HealthType = {
  AGE: "OldAge",
  DISEASE: "Sickness",
  HANDICAP: "Disability",
  GOOD_MOBILITY: "Walk",
  OK_MOBILITY: "WalkWithHelp",
  BAD_MOBILITY: "Wheelchair",
  NO_MOBILITY: "MotionLess",
  ATTENTIVE: "Perfect",
  MORAL_LONELINESS: "MoralLoneliness",
  ALOOF: "Good",
  DEGRADING_MENTAL: "Bad",
};

export const ServicesType = {
  RELAXATION: "relaxation",
  PHYSICAL_ACTIVITY: "sport",
  SOFT_PHYSICAL_ACTIVITY: "softPhysicalActivity",
  /*  OUTSIDE_SPORT: "outsideSport", */
  NUTRITION: "nutrition",
  HAIR_CARE: "hairCare",
  BODY_CARE: "bodyCare",
  HAND_FEET_CARE: "handFeetCare",
  MAKEUP: "makeUp",
  MUSIC: "musicArt",
  DRAWING: "drawArt",
  BATHROOM: "bathroom",
};

export const NeedServicesProType = {
  HOME_HELP: "homeHelp", //Entretien de la maison
  SPORT: "sport", //Sport, bien être beauté
  BEAUTY: "beauty", //Visites et aide au repas
  ARTS: "arts", //Compagnie loisirs à la maison
};

export const NeedServicesType = {
  HOBBIES: "Hobbies", //Compagnie loisirs à la maison
  DAYWATCH: "DayWatch", //Visites et aide au repas
  SPORT: "Sport", //Sport, bien être beauté
  ESCORT: "Escort", //Accompagnement sortie / rdv
  BATHROOM: "Bathroom", //Aide à la toilette
  HELPING_HAND: "HelpingHand", // Coup de pouce du quotidien
  HOME_MAINTENANCE: "HomeMaintenance", //Entretien de la maison
  NIGHTWATCH: "NightWatch", //Surveillance de nuit,
};

export const ActivityType = {
  TALKING: "talking",
  READING: "reading",
  COOKING: "cooking",
  GARDENING: "gardening",
  CARDS: "cards",
  COGNITIVE_GAMES: "cognitiveGames",
  GAMES: "games",
  MUSIC: "music",
  DRAWING: "drawing",
  KNITTING: "knitting",
  LABOR: "labor",
  TINKERING: "tinkering",
  SHOPPING: "shopping",
  CINEMA: "cinema",
  RESTAURANT: "restaurant",
  WALKING: "walking",
  MUSEUM: "museum",
  FRIENDS: "friends",
  OUTSIDE_MEETING: "outsideMeeting",
  SELFCARE: "selfCare",
  RELAXATION: "relaxation",
  PHYSICAL_ACTIVITY: "physicalActivity",
  SOFT_PHYSICAL_ACTIVITY: "softPhysicalActivity",
  OUTSIDE_SPORT: "outsideSport",
  SPORT: "sport",
  HOUSE_CARE: "houseCare",
  ORGANIZING: "organizing",
  DAILY_CHORE: "dailyChore",
  INTERNET: "internet",
  ANIMALS: "animals",
  OTHER: "other",
  HAIR_CARE: "hairCare",
  BODY_CARE: "bodyCare",
  HAND_FEET_CARE: "handFeetCare",
  MAKEUP: "makeUp",
  MUSIC_ART: "musicArt",
  DRAW_ART: "drawArt",
  ADMINISTRATIVE_AID: "administrativeAid",
  CULTURAL_OUTINGS: "culturalOutings",
  GROCERY_SHOPPING: "groceryShopping",
  NUTRITION: "nutrition",
};
export const healthGrids = {
  autonomyLoss: [HealthType.AGE, HealthType.DISEASE, HealthType.HANDICAP],
  mobilities: [
    HealthType.GOOD_MOBILITY,
    HealthType.OK_MOBILITY,
    HealthType.BAD_MOBILITY,
    HealthType.NO_MOBILITY,
  ],
  mentalHealth: [
    HealthType.ATTENTIVE,
    HealthType.MORAL_LONELINESS,
    HealthType.ALOOF,
    HealthType.DEGRADING_MENTAL,
  ],
  neededServicesPro: [
    NeedServicesProType.SPORT,
    NeedServicesProType.BEAUTY,
    NeedServicesProType.ARTS,
    NeedServicesProType.HOME_HELP,
  ],
  servicesPro: [
    ServicesType.RELAXATION,
    ServicesType.PHYSICAL_ACTIVITY,
    ServicesType.SOFT_PHYSICAL_ACTIVITY,
    /*   ServicesType.OUTSIDE_SPORT, */
    ServicesType.NUTRITION,
    ServicesType.HAIR_CARE,
    ServicesType.BODY_CARE,
    ServicesType.HAND_FEET_CARE,
    ServicesType.DRAWING,
    ServicesType.MAKEUP,
    ServicesType.MUSIC,
  ],
  neededServices: [
    NeedServicesType.HOBBIES,
    NeedServicesType.DAYWATCH,
    NeedServicesType.SPORT,
    NeedServicesType.ESCORT,
    NeedServicesType.HELPING_HAND,
    NeedServicesType.HOME_MAINTENANCE,
    NeedServicesType.NIGHTWATCH,
    NeedServicesType.BATHROOM,
  ],
  neededServicesHelper: [
    NeedServicesType.HOBBIES,
    NeedServicesType.DAYWATCH,
    NeedServicesType.ESCORT,
    NeedServicesType.HELPING_HAND,
    NeedServicesType.HOME_MAINTENANCE,
    NeedServicesType.NIGHTWATCH,
    NeedServicesType.BATHROOM,
  ],
  activities: [
    ActivityType.TALKING,
    ActivityType.READING,
    ActivityType.COOKING,
    ActivityType.GARDENING,
    ActivityType.CARDS,
    ActivityType.COGNITIVE_GAMES,
    ActivityType.GAMES,
    ActivityType.MUSIC,
    ActivityType.DRAWING,
    ActivityType.KNITTING,
    ActivityType.LABOR,
    ActivityType.TINKERING,
    ActivityType.SHOPPING,
    ActivityType.CINEMA,
    ActivityType.RESTAURANT,
    ActivityType.WALKING,
    ActivityType.MUSEUM,
    ActivityType.FRIENDS,
    ActivityType.OUTSIDE_MEETING,
    ActivityType.SELFCARE,
    ActivityType.RELAXATION,
    ActivityType.PHYSICAL_ACTIVITY,
    ActivityType.SOFT_PHYSICAL_ACTIVITY,
    ActivityType.OUTSIDE_SPORT,
    ActivityType.SPORT,
    ActivityType.HOUSE_CARE,
    ActivityType.ORGANIZING,
    ActivityType.DAILY_CHORE,
    ActivityType.INTERNET,
    ActivityType.ANIMALS,
    ActivityType.OTHER,
    ActivityType.HAIR_CARE,
    ActivityType.BODY_CARE,
    ActivityType.HAND_FEET_CARE,
    ActivityType.MAKEUP,
    ActivityType.MUSIC_ART,
    ActivityType.DRAW_ART,
    ActivityType.ADMINISTRATIVE_AID,
  ],
  activitiesHelper: [
    ActivityType.TALKING,
    ActivityType.GAMES,
    ActivityType.COGNITIVE_GAMES,
    ActivityType.COOKING,
    ActivityType.LABOR,
    ActivityType.GARDENING,
    ActivityType.TINKERING,
    ActivityType.KNITTING,
    ActivityType.DRAWING,
    ActivityType.MUSIC,
    ActivityType.GROCERY_SHOPPING,
    ActivityType.CULTURAL_OUTINGS,
    ActivityType.WALKING,
    ActivityType.RESTAURANT,
    ActivityType.HOUSE_CARE,
    ActivityType.DAILY_CHORE,
    ActivityType.ORGANIZING,
    ActivityType.INTERNET,
    ActivityType.ANIMALS,
    ActivityType.ADMINISTRATIVE_AID,
  ],
};

export const NeededServicesProData = {
  [NeedServicesProType.SPORT]: { text: "le Sport & Bien-être" },
  [NeedServicesProType.BEAUTY]: { text: "la Beauté" },
  [NeedServicesProType.ARTS]: { text: "les Arts" },
  [NeedServicesProType.HOME_HELP]: { text: "l'Aide à domicile" },
};

export const ServicesData = {
  [ServicesType.RELAXATION]: {
    text: "Activité de relaxation",
  },
  [ServicesType.PHYSICAL_ACTIVITY]: {
    text: "Activités sportives",
  },
  [ServicesType.SOFT_PHYSICAL_ACTIVITY]: {
    text: "Activités motrices douces",
  },
  /* [ServicesType.OUTSIDE_SPORT]: {
    text: "Accompagnement sorties sportives",
  }, */
  [ServicesType.NUTRITION]: {
    text: "Bien-être & nutrition",
  },

  [ServicesType.HAIR_CARE]: {
    text: "Coiffure",
  },
  [ServicesType.BODY_CARE]: {
    text: "Soins Corps/visage",
  },
  [ServicesType.HAND_FEET_CARE]: {
    text: "Soins Mains/Pieds",
  },
  [ServicesType.MAKEUP]: {
    text: "Maquillage",
  },
  [ServicesType.MUSIC]: {
    text: "Musique/Chant",
  },
  [ServicesType.DRAWING]: {
    text: "Dessin/Peinture",
  },
};
export const NeededServicesData = {
  [NeedServicesType.HOBBIES]: { text: "Compagnie & loisirs à la maison" },
  [NeedServicesType.SPORT]: { text: "Sport, bien-être & beauté" },
  [NeedServicesType.DAYWATCH]: { text: "Visites & aide aux repas" },
  [NeedServicesType.ESCORT]: { text: "Accompagnement sorties ou rdv" },
  [NeedServicesType.BATHROOM]: { text: "Aide à la toilette & prise de repas" },
  [NeedServicesType.HELPING_HAND]: { text: "Petits services à domicile" },
  [NeedServicesType.HOME_MAINTENANCE]: { text: "Entretien de la maison" },
  [NeedServicesType.NIGHTWATCH]: { text: "Garde de nuit" },
};

export const ActivityData = {
  [ActivityType.TALKING]: {
    text: "Papoter",
  },
  [ActivityType.READING]: {
    text: "Lire",
  },
  [ActivityType.COOKING]: {
    text: "Cuisiner",
  },
  [ActivityType.GARDENING]: {
    text: "Jardiner",
  },
  [ActivityType.CARDS]: {
    text: "Jeux de cartes",
  },
  [ActivityType.GAMES]: {
    text: "Jeux de société",
  },
  [ActivityType.COGNITIVE_GAMES]: {
    text: "Jeux cognitifs",
  },
  [ActivityType.MUSIC]: {
    text: "Jouer d’un instrument",
  },
  [ActivityType.DRAWING]: {
    text: "Dessiner / Peindre",
  },
  [ActivityType.KNITTING]: {
    text: "Coudre / Tricoter",
  },
  [ActivityType.LABOR]: {
    text: "Travaux manuels",
  },
  [ActivityType.TINKERING]: {
    text: "Bricoler",
  },
  [ActivityType.SHOPPING]: {
    text: "Shopping",
  },
  [ActivityType.CINEMA]: {
    text: "Ciné - Théatre",
  },
  [ActivityType.RESTAURANT]: {
    text: "Restaurant",
  },
  [ActivityType.WALKING]: {
    text: "Se promener",
  },
  [ActivityType.MUSEUM]: {
    text: "Musée / culture",
  },
  [ActivityType.FRIENDS]: {
    text: "Voir des amis",
  },
  [ActivityType.OUTSIDE_MEETING]: {
    text: "RDV extérieurs",
  },
  [ActivityType.SELFCARE]: {
    text: "Prendre soin de soi",
  },
  [ActivityType.RELAXATION]: {
    text: "Activité de relaxation",
  },
  [ActivityType.PHYSICAL_ACTIVITY]: {
    text: "Activité Physique",
  },
  [ActivityType.SOFT_PHYSICAL_ACTIVITY]: {
    text: "Activités Motrices Douces",
  },
  [ActivityType.OUTSIDE_SPORT]: {
    text: "Accompagnement sorties sportives",
  },
  [ActivityType.SPORT]: {
    text: "Activités sportives",
  },
  [ActivityType.HOUSE_CARE]: {
    text: "Ménage",
  },
  [ActivityType.ORGANIZING]: {
    text: "Petits rangements du quotidien",
  },
  [ActivityType.DAILY_CHORE]: {
    text: "Petits travaux du quotidien",
  },
  [ActivityType.INTERNET]: {
    text: "Surfer sur internet",
  },
  [ActivityType.ANIMALS]: {
    text: "S’occuper d’animaux",
  },
  [ActivityType.OTHER]: {
    text: "Autre",
  },
  [ActivityType.HAIR_CARE]: {
    text: "Coiffure",
  },
  [ActivityType.BODY_CARE]: {
    text: "Soins corps/visage",
  },
  [ActivityType.HAND_FEET_CARE]: {
    text: "Soins mains/pieds",
  },
  [ActivityType.MAKEUP]: {
    text: "Maquillage",
  },
  [ActivityType.MUSIC_ART]: {
    text: "Musique/Chant",
  },
  [ActivityType.DRAW_ART]: {
    text: "Dessin/Peinture",
  },
  [ActivityType.ADMINISTRATIVE_AID]: {
    text: "Aide administrative",
  },
  [ActivityType.CULTURAL_OUTINGS]: {
    text: "Sorties culturelles",
  },
  [ActivityType.GROCERY_SHOPPING]: {
    text: "Courses",
  },
  [ActivityType.NUTRITION]: {
    text: "Bien-être & nutrition",
  },
};

export const HealthData = {
  [HealthType.AGE]: {
    text: "L'âge",
  },
  [HealthType.DISEASE]: {
    text: "La maladie",
  },
  [HealthType.HANDICAP]: {
    text: "Le handicap",
  },
  [HealthType.GOOD_MOBILITY]: {
    text: "Se déplace seul(e)",
  },
  [HealthType.OK_MOBILITY]: {
    text: "Marche avec un peu d'aide",
  },
  [HealthType.BAD_MOBILITY]: {
    text: "Ne se déplace qu'en fauteuil",
  },
  [HealthType.NO_MOBILITY]: {
    text: "Ne peut se déplacer",
  },
  [HealthType.ATTENTIVE]: {
    text: "Tout va bien",
  },
  [HealthType.MORAL_LONELINESS]: {
    text: "Perte de moral et solitude",
  },
  [HealthType.ALOOF]: {
    text: "Perd parfois un peu la tête",
  },
  [HealthType.DEGRADING_MENTAL]: {
    text: "Perte d'autonomie mentale",
  },
};
